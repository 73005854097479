.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

/* This CSS fixes the issue where fonts appear bolder in Firefox */
@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

@font-face {
  font-family: "T-Safe-Bold";
  src: url("../fonts/Extratype-Eina04-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "T-Safe-BoldItalic";
  src: url("../fonts/Extratype-Eina04-BoldItalic.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "T-Safe-Light";
  src: url("../fonts/Extratype-Eina04-Light.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "T-Safe-LightItalic";
  src: url("../fonts/Extratype-Eina04-LightItalic.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "T-Safe-Regular";
  src: url("../fonts/Extratype-Eina04-Regular.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "T-Safe-RegularItalic";
  src: url("../fonts/Extratype-Eina04-RegularItalic.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "T-Safe-SemiBold";
  src: url("../fonts/Extratype-Eina04-SemiBold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "T-Safe-SemiBoldItalic";
  src: url("../fonts/Extratype-Eina04-SemiboldItalic.otf") format("opentype");
  font-style: normal;
}
