.grid-container {
  display: grid;
  gap: 2%;
  padding: 2%;
  grid-template-columns: 32% 32% 32%;
  grid-template-rows: 12% 12% 20% 12% 12% 18%;
  height: 825px;
}

/*
.grid-container {
  display: grid;
  gap: 25px;
  padding: 25px;
  grid-template-columns: 500px 500px 500px 500px;
  grid-template-rows: 250px 250px 250px 250px 250px;
  height: 1250px;
}
*/

.grid-container > div {
  outline: 2px;
  outline-style: solid;
  border-radius: 5px;
  outline-color: #d8d8d8;
}

.dashboard-masonry {
  outline: 2px;
  outline-style: solid;
  border-radius: 5px;
  outline-color: #d8d8d8;
  margin: 8px 0px 8px 0px;
}

.dashboard-top {
  outline: 2px;
  outline-style: solid;
  border-radius: 5px;
  outline-color: #d8d8d8;
  margin: 13px 13px 13px 0px;
}

.dashboard-container {
  padding: 3px 0px 0px 13px;
}

.dashboard-checkbox {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.dashboard-checkbox-label {
  margin-left: 10px;
  vertical-align: top;
  font-size: 14px;
}

.dashboard-csv-link {
  font-weight: bold;
}
